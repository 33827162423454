


































import {axiosPost} from '@/utils/axiosWrapper';
import Vue from 'vue';
import {mapActions, mapState} from 'vuex';

export default Vue.extend({
  name: 'Login',
  beforeMount() {
    this.checkCookie();
  },
  data() {
    return {
      rememberMeChecked: true,
      email: "",
      password: "",
    }
  },
  computed: {
    ...mapState([
      'loggingIn',
      'loginError',
      'loginSuccessful'
    ])
  },
  methods: {
    ...mapActions([
      'doLogin'
    ]),
    loginSubmit() {
      this.doLogin({
        email: this.email,
        password: this.password,
        rememberMeChecked: this.rememberMeChecked
      });
    },
    checkCookie: function () {
      if (localStorage.getItem("email") != null && localStorage.getItem("password") != null) {
        const localEmail = localStorage.getItem("email");
        const localPassword = localStorage.getItem("password");
        if (localEmail)
          this.email = localEmail
        if (localPassword)
          this.password = localPassword
      }
    },
    forgotPW: function () {
      axiosPost('/users/password/new', {
        email: this.email.toLowerCase(),
      })
          .then(() => alert("We just sent you a new temporary password.\nPlease change it after login."))
          .catch((err) => console.error(err))
    },
  },
});
